<template>
  <div class="purchase-order">
    <QueryForm v-model="queryForm" :query-btn-loading="tableLoading" @query="handleQuery" @reset="handleQueryFormReset">
      <el-form-item label="BU">
        <Select
          v-model="queryForm.bu"
          api-key="buList"
          clearable
        />
      </el-form-item>
      <el-form-item label="平台">
        <Select
          v-model="queryForm.platform"
          api-key="platformList"
          clearable
          @responseData="val=>platformList=val"
          @change="queryForm.site = ''"
        />
      </el-form-item>
      <el-form-item label="站点">
        <Select
          v-model="queryForm.site"
          :params="{ sellPlatformId:queryForm.platform}"
          ganged
          clearable
          :ganged-prompt="$t('page.selectPlaceholder') + ' 平台'"
          api-key="siteList"
          @responseData="val=>siteList=val"
        />
      </el-form-item>
      <el-form-item v-for="(item,index) in queryForm.detail" :key="index" :label="item.label">
        <el-select v-model="item.username" clearable :placeholder="$t('page.selectPlaceholder')" filterable>
          <el-option
            v-for="i in userList"
            :key="i.employeeId"
            :label="i.username"
            :disabled="item.role!==4&&i.username==='Style运营负责人'"
            :value="i.username"
          />
        </el-select>
      </el-form-item>
    </QueryForm>
    <el-row :gutter="10" class="mt-3 mb-3">
      <el-button type="primary" @click="handleAdd">新建</el-button>
      <el-button type="primary" :disabled="!multipleSelection.length" @click="handleDelete">删除</el-button>
      <el-button type="primary" @click="handleUpdateOperate('operate')">修改运营负责人</el-button>
      <el-button type="primary" @click="handleUpdateOperate('plan')">修改计划负责人</el-button>
      <el-button type="primary" @click="uploadDialogVisible=true">批量导入</el-button>
      <el-button type="primary" @click="handleManualSync">手动执行同步</el-button>
      <el-button type="primary" :loading="syncLogLoading" @click="handleSyncLog">查看同步记录</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="val => {multipleSelection=val}"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="bu"
        label="BU"
        min-width="90"
      />
      <el-table-column
        prop="platform"
        label="平台"
        min-width="90"
      />
      <el-table-column
        prop="site"
        label="站点"
        min-width="80"
      />

      <el-table-column
        v-for="(col, index) in queryForm.detail"
        :key="index"
        :label="col.label"
      >
        <template slot-scope="scope">
          {{ handleRole(scope.row.detail,col) }}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="同步记录" :visible.sync="recordDialogVisible" width="950px" :close-on-click-modal="false">
      <Table
        :table-data="detailTable"
        :max-height="'450px'"
        :columns="recordColumns"
      />
      <Paging :pager="logPager" end class="mt-3" @pagination="pagerLogUpdate" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="recordDialogVisible=false">关闭</el-button>
      </span>
    </el-dialog>
    <Upload v-model="uploadDialogVisible" file-name="负责人同步配置模板" :columns="detailsColumns" :view-api="importSynPrincipalView" :submit-api="importSynPrincipal" :template-download-api="exportSynPrincipalTemplate" @refresh="handleQuery" />
    <Paging :pager="pager" end @pagination="pagerUpdate" />
    <Update v-model="updateOperationVisible" :row="row" :find-length="styleList.length" @refresh="handleQuery" />
    <BatchUpdate v-model="batchUpdateOperationVisible" :row="row" :find-length="styleList.length" @refresh="handleQuery" />
  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
import { tableColumns, recordColumns, detailsColumns } from '@/constant/tablecolumns'
import Upload from '../responsible-automatic/components/Upload'
import Update from '../responsible-person/components/Update'
import BatchUpdate from '../responsible-person/components/BatchUpdate'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { listSyncLog, synByHand, delSynPrincipal, importSynPrincipalView, importSynPrincipal, exportSynPrincipalTemplate, importPrincipalBatch, pageSynPrincipalList } from '@/api/configuration'
import Table from '@/components/Table'
import { userList } from '@/api/listSelection'

export default {

  components: {
    QueryForm,
    Select,
    Paging,
    Upload,
    Update,
    BatchUpdate,
    Table
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      detailsColumns,
      syncLogLoading: false,
      siteList: [],
      exportSynPrincipalTemplate,
      importSynPrincipal, importSynPrincipalView, recordColumns, recordDialogVisible: false, detailTable: [],
      uploadDialogVisible: false,
      platformList: [], styleList: [],
      optionsConfig: { key: 'id', label: 'styleName', value: 'styleName' },
      tableLoading: false, updateOperationVisible: false, batchUpdateOperationVisible: false, exportLoading: false,
      tableColumns,
      tableData: [],
      userList: [],
      file: '', importSubmitLoading: false, multipleSelection: [],
      queryForm: {
        bu: '',
        platform: '',
        site: '',
        detail: [
          { role: 2, label: 'BU或市场负责人', username: '', newLabel: 'buOperationPrincipal' },
          { role: 3, label: 'BG运营负责人', username: '', newLabel: 'bgOperationPrincipal' },
          { role: 4, label: 'Style计划负责人', username: '', newLabel: 'stylePlanPrincipal' },
          { role: 5, label: 'BU计划负责人', username: '', newLabel: 'buPlanPrincipal' },
          { role: 6, label: 'BG计划负责人', username: '', newLabel: 'bgPlanPrincipal' },
          { role: 7, label: 'PJM', username: '', newLabel: 'pjm' }]
      },
      row: {},
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      logPager: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      const { platform } = this.queryForm
      const platformName = this.platformList.find(item => item.id === platform)?.platformName
      return Object.assign({},
        this.pager,
        this.queryForm, { platform: platformName }
      )
    }
  },
  async mounted() {
    this.handleQuery()
    const { datas } = await userList()
    datas.unshift({ username: 'Style运营负责人', employeeId: 'Style运营负责人' })
    this.userList = datas
  },
  methods: {
    async  handleSyncLog() {
      try {
        this.syncLogLoading = true
        const { code, datas: { pager, records }} = await listSyncLog(this.logPager)
        if (code === 0) {
          this.logPager = pager
          this.detailTable = records
          this.recordDialogVisible = true
        }
      } finally {
        this.syncLogLoading = false
      }
    },
    handleRole(row, col) {
      return row ? row.find(e => e.role === col.role)?.usernameList.join(',') : ''
    },
    getDetails() {
      return this._getAllCommodityDict('PRINCIPAL_CONFIG_TYPE').map(item => { return { ...item, role: item.val, username: '' } })
    },
    handleDelete() {
      this.$confirm('此操作将删除选中数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { code, msg } = await delSynPrincipal(this.multipleSelection)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
        }
        this.handleQuery()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleManualSync() {
      this.$confirm('是否确认手动执行同步？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { code, msg } = await synByHand()
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.handleQuery()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleAdd() {
      this.updateOperationVisible = true
      this.row = Object.assign({}, { title: '新建', type: 'add', page: 'auto' })
    },
    handleUpdateOperate(flag) {
      if (this.multipleSelection.length === 1) {
        this.updateOperationVisible = true
        const { bu } = this.multipleSelection[0]
        this.row = Object.assign({}, { title: flag === 'operate' ? '修改运营负责人' : '修改计划负责人', type: 'edit', flag }, this.multipleSelection[0], { bu: [bu], page: 'auto' })
      } else {
        this.$message.warning('只能选中一条数据进行修改')
      }
    },
    async uploadFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { code, msg } = await importPrincipalBatch(form)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.file = ''
          this.handleQuery()
        }
      } finally {
        this.$refs.uploadForm.clearFiles()
      }
    },
    pagerLogUpdate(val) {
      this.logPager = val
      this.handleSyncLog()
    },
    pagerUpdate(val) {
      this.pager = val
      this._queryLists()
    },
    handleQuery() {
      this.pager.current = 1
      this._queryLists()
    },
    async _queryLists() {
      try {
        this.tableLoading = true
        const { code, datas: { pager: { total }, records = [] }} = await pageSynPrincipalList(this.queryParams)
        if (code === 0) {
          this.tableData = records.map(item => {
            item.detail = item.detail.map(e => {
              item[[e.role]] = e['usernameList'].join(',')
              // newLabel 用于删除接口特殊传参
              const newLabel = this.queryForm.detail.find(ee => ee.role === e.role)?.newLabel
              item[newLabel] = e['usernameList'].join(',')
              return { ...e, [e.role]: e['usernameList'], label: this.queryForm.detail.find(ee => ee.role === e.role)?.label }
            })
            return { ...item }
          })
          this.pager.total = total
        }
        console.log(this.tableData)
      } finally {
        this.tableLoading = false
      }
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    }
  }
}
</script>

<style>

</style>
